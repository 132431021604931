.yate {
  position: relative;
  /* BASICS */
  /* PADDING */
  /* GUTTER */
  /* CURSOR */
  /* Shown when moving in bi-directional text */
  /* Can style cursor different in overwrite (non-insert) mode */
  /* DEFAULT THEME */
  /* Default styles for common addons */
  /* STOP */
  /* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */
  /* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
  /* Force content-box sizing for the elements where we expect it */
  /* IE7 hack to prevent it from returning funny offsetTops on the spans */
  /* Used to force a border model for a node */
  /* See issue #2901 */
  /* Help users use markselection to safely style text background */ }
  .yate .CodeMirror {
    /* Set height, width, borders, and global font properties here */
    font-family: monospace;
    height: 300px;
    color: black; }
  .yate .CodeMirror-lines {
    padding: 4px 0;
    /* Vertical padding around content */ }
  .yate .CodeMirror pre {
    padding: 0 4px;
    /* Horizontal padding of content */ }
  .yate .CodeMirror-scrollbar-filler, .yate .CodeMirror-gutter-filler {
    background-color: white;
    /* The little square between H and V scrollbars */ }
  .yate .CodeMirror-gutters {
    border-right: 1px solid #ddd;
    background-color: #f7f7f7;
    white-space: nowrap; }
  .yate .CodeMirror-linenumber {
    padding: 0 3px 0 5px;
    min-width: 20px;
    text-align: right;
    color: #999;
    white-space: nowrap; }
  .yate .CodeMirror-guttermarker {
    color: black; }
  .yate .CodeMirror-guttermarker-subtle {
    color: #999; }
  .yate .CodeMirror-cursor {
    border-left: 1px solid black;
    border-right: none;
    width: 0; }
  .yate .CodeMirror div.CodeMirror-secondarycursor {
    border-left: 1px solid silver; }
  .yate .cm-fat-cursor .CodeMirror-cursor {
    width: auto;
    border: 0 !important;
    background: #7e7; }
  .yate .cm-fat-cursor div.CodeMirror-cursors {
    z-index: 1; }
  .yate .cm-animate-fat-cursor {
    width: auto;
    border: 0;
    -webkit-animation: blink 1.06s steps(1) infinite;
    animation: blink 1.06s steps(1) infinite;
    background-color: #7e7; }

@-webkit-keyframes blink {
  0% { }
  50% {
    background-color: transparent; }
  100% { } }

@keyframes blink {
  0% { }
  50% {
    background-color: transparent; }
  100% { } }
  .yate .cm-tab {
    display: inline-block;
    text-decoration: inherit; }
  .yate .CodeMirror-rulers {
    position: absolute;
    left: 0;
    right: 0;
    top: -50px;
    bottom: -20px;
    overflow: hidden; }
  .yate .CodeMirror-ruler {
    border-left: 1px solid #ccc;
    top: 0;
    bottom: 0;
    position: absolute; }
  .yate .cm-s-default .cm-header {
    color: blue; }
  .yate .cm-s-default .cm-quote {
    color: #090; }
  .yate .cm-negative {
    color: #d44; }
  .yate .cm-positive {
    color: #292; }
  .yate .cm-header, .yate .cm-strong {
    font-weight: bold; }
  .yate .cm-em {
    font-style: italic; }
  .yate .cm-link {
    text-decoration: underline; }
  .yate .cm-strikethrough {
    text-decoration: line-through; }
  .yate .cm-s-default .cm-keyword {
    color: #708; }
  .yate .cm-s-default .cm-atom {
    color: #219; }
  .yate .cm-s-default .cm-number {
    color: #164; }
  .yate .cm-s-default .cm-def {
    color: #00f; }
  .yate .cm-s-default .cm-variable-2 {
    color: #05a; }
  .yate .cm-s-default .cm-variable-3 {
    color: #085; }
  .yate .cm-s-default .cm-comment {
    color: #a50; }
  .yate .cm-s-default .cm-string {
    color: #a11; }
  .yate .cm-s-default .cm-string-2 {
    color: #f50; }
  .yate .cm-s-default .cm-meta {
    color: #555; }
  .yate .cm-s-default .cm-qualifier {
    color: #555; }
  .yate .cm-s-default .cm-builtin {
    color: #30a; }
  .yate .cm-s-default .cm-bracket {
    color: #997; }
  .yate .cm-s-default .cm-tag {
    color: #170; }
  .yate .cm-s-default .cm-attribute {
    color: #00c; }
  .yate .cm-s-default .cm-hr {
    color: #999; }
  .yate .cm-s-default .cm-link {
    color: #00c; }
  .yate .cm-s-default .cm-error {
    color: #f00; }
  .yate .cm-invalidchar {
    color: #f00; }
  .yate .CodeMirror-composing {
    border-bottom: 2px solid; }
  .yate div.CodeMirror span.CodeMirror-matchingbracket {
    color: #0f0; }
  .yate div.CodeMirror span.CodeMirror-nonmatchingbracket {
    color: #f22; }
  .yate .CodeMirror-matchingtag {
    background: rgba(255, 150, 0, 0.3); }
  .yate .CodeMirror-activeline-background {
    background: #e8f2ff; }
  .yate .CodeMirror {
    position: relative;
    overflow: hidden;
    background: white; }
  .yate .CodeMirror-scroll {
    overflow: scroll !important;
    /* Things will break if this is overridden */
    /* 30px is the magic margin used to hide the element's real scrollbars */
    /* See overflow: hidden in .CodeMirror */
    margin-bottom: -30px;
    margin-right: -30px;
    padding-bottom: 30px;
    height: 100%;
    outline: none;
    /* Prevent dragging from highlighting the element */
    position: relative; }
  .yate .CodeMirror-sizer {
    position: relative;
    border-right: 30px solid transparent; }
  .yate .CodeMirror-vscrollbar, .yate .CodeMirror-hscrollbar, .yate .CodeMirror-scrollbar-filler, .yate .CodeMirror-gutter-filler {
    position: absolute;
    z-index: 6;
    display: none; }
  .yate .CodeMirror-vscrollbar {
    right: 0;
    top: 0;
    overflow-x: hidden;
    overflow-y: scroll; }
  .yate .CodeMirror-hscrollbar {
    bottom: 0;
    left: 0;
    overflow-y: hidden;
    overflow-x: scroll; }
  .yate .CodeMirror-scrollbar-filler {
    right: 0;
    bottom: 0; }
  .yate .CodeMirror-gutter-filler {
    left: 0;
    bottom: 0; }
  .yate .CodeMirror-gutters {
    position: absolute;
    left: 0;
    top: 0;
    min-height: 100%;
    z-index: 3; }
  .yate .CodeMirror-gutter {
    white-space: normal;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: -30px;
    /* Hack to make IE7 behave */
    *zoom: 1;
    *display: inline; }
  .yate .CodeMirror-gutter-wrapper {
    position: absolute;
    z-index: 4;
    background: none !important;
    border: none !important; }
  .yate .CodeMirror-gutter-background {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 4; }
  .yate .CodeMirror-gutter-elt {
    position: absolute;
    cursor: default;
    z-index: 4; }
  .yate .CodeMirror-gutter-wrapper {
    -webkit-user-select: none;
    user-select: none; }
  .yate .CodeMirror-lines {
    cursor: text;
    min-height: 1px;
    /* prevents collapsing before first draw */ }
  .yate .CodeMirror pre {
    /* Reset some styles that the rest of the page might have set */
    border-radius: 0;
    border-width: 0;
    background: transparent;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    white-space: pre;
    word-wrap: normal;
    line-height: inherit;
    color: inherit;
    z-index: 2;
    position: relative;
    overflow: visible;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none; }
  .yate .CodeMirror-wrap pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: normal; }
  .yate .CodeMirror-linebackground {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0; }
  .yate .CodeMirror-linewidget {
    position: relative;
    z-index: 2;
    overflow: auto; }
  .yate .CodeMirror-code {
    outline: none; }
  .yate .CodeMirror-scroll,
  .yate .CodeMirror-sizer,
  .yate .CodeMirror-gutter,
  .yate .CodeMirror-gutters,
  .yate .CodeMirror-linenumber {
    box-sizing: content-box; }
  .yate .CodeMirror-measure {
    position: absolute;
    width: 100%;
    height: 0;
    overflow: hidden;
    visibility: hidden; }
  .yate .CodeMirror-cursor {
    position: absolute;
    pointer-events: none; }
  .yate .CodeMirror-measure pre {
    position: static; }
  .yate div.CodeMirror-cursors {
    visibility: hidden;
    position: relative;
    z-index: 3; }
  .yate div.CodeMirror-dragcursors {
    visibility: visible; }
  .yate .CodeMirror-focused div.CodeMirror-cursors {
    visibility: visible; }
  .yate .CodeMirror-selected {
    background: #d9d9d9; }
  .yate .CodeMirror-focused .CodeMirror-selected {
    background: #d7d4f0; }
  .yate .CodeMirror-crosshair {
    cursor: crosshair; }
  .yate .CodeMirror-line::selection, .yate .CodeMirror-line > span::selection, .yate .CodeMirror-line > span > span::selection {
    background: #d7d4f0; }
  .yate .CodeMirror-line::-moz-selection, .yate .CodeMirror-line > span::-moz-selection, .yate .CodeMirror-line > span > span::-moz-selection {
    background: #d7d4f0; }
  .yate .cm-searching {
    background: #ffa;
    background: rgba(255, 255, 0, 0.4); }
  .yate .CodeMirror span {
    *vertical-align: text-bottom; }
  .yate .cm-force-border {
    padding-right: .1px; }
  @media print {
    .yate {
      /* Hide the cursor when printing */ }
      .yate .CodeMirror div.CodeMirror-cursors {
        visibility: hidden; } }
  .yate .cm-tab-wrap-hack:after {
    content: ''; }
  .yate span.CodeMirror-selectedtext {
    background: none; }
  .yate .CodeMirror-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    z-index: 9; }
  .yate .CodeMirror-foldmarker {
    color: blue;
    text-shadow: #b9f 1px 1px 2px, #b9f -1px -1px 2px, #b9f 1px -1px 2px, #b9f -1px 1px 2px;
    font-family: arial;
    line-height: .3;
    cursor: pointer; }
  .yate .CodeMirror-foldgutter {
    width: .7em; }
  .yate .CodeMirror-foldgutter-open,
  .yate .CodeMirror-foldgutter-folded {
    cursor: pointer; }
  .yate .CodeMirror-foldgutter-open:after {
    content: "\25BE"; }
  .yate .CodeMirror-foldgutter-folded:after {
    content: "\25B8"; }
  .yate .CodeMirror-dialog {
    position: absolute;
    left: 0;
    right: 0;
    background: inherit;
    z-index: 15;
    padding: .1em .8em;
    overflow: hidden;
    color: inherit; }
  .yate .CodeMirror-dialog-top {
    border-bottom: 1px solid #eee;
    top: 0; }
  .yate .CodeMirror-dialog-bottom {
    border-top: 1px solid #eee;
    bottom: 0; }
  .yate .CodeMirror-dialog input {
    border: none;
    outline: none;
    background: transparent;
    width: 20em;
    color: inherit;
    font-family: monospace; }
  .yate .CodeMirror-dialog button {
    font-size: 70%; }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }
  .yate .yate_btn {
    color: #333;
    border: 1px solid transparent;
    background-color: #fff;
    border-color: #ccc;
    border-width: 1px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    padding: 6px 12px;
    border-radius: 4px;
    -webkit-user-select: none;
    user-select: none;
    -webkit-appearance: button;
    /* margin: 0; */
    overflow: visible;
    box-sizing: border-box; }
    .yate .yate_btn.btn_icon {
      padding: 4px 8px; }
    .yate .yate_btn[disabled], .yate .yate_btn.disabled {
      cursor: default;
      opacity: .50;
      filter: alpha(opacity=50);
      box-shadow: none; }
    .yate .yate_btn:hover {
      outline: 0;
      background-color: #ebebeb;
      border-color: #adadad; }
    .yate .yate_btn:focus, .yate .yate_btn.selected {
      color: #fff;
      outline: 0;
      background-color: #337ab7;
      border-color: #337ab7; }
    .yate .yate_btn.btn_icon:focus {
      color: #333;
      border: 1px solid transparent;
      background-color: #fff;
      border-color: #ccc; }
    .yate .yate_btn.yate_btn-sm {
      padding: 1px 5px;
      font-size: 12px;
      line-height: 1.5;
      border-radius: 3px; }
  .yate .yate_buttons {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 5; }
    .yate .yate_buttons .yate_share {
      cursor: pointer;
      height: 20px;
      width: 20px;
      margin-top: 3px; }
    .yate .yate_buttons div {
      vertical-align: top;
      margin-left: 5px; }
      .yate .yate_buttons div.yate_sharePopup {
        position: absolute;
        padding: 4px;
        margin-left: 0px;
        background-color: #fff;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
        width: 600px;
        height: auto;
        display: -webkit-box;
        display: flex;
        margin-left: 10px; }
        .yate .yate_buttons div.yate_sharePopup .inputWrapper {
          -webkit-box-flex: 100;
                  flex-grow: 100; }
        .yate .yate_buttons div.yate_sharePopup input {
          float: left;
          width: 100%;
          border: 0px;
          -ms-box-sizing: border-box;
          /* ie8 */
          -khtml-box-sizing: border-box;
          /* konqueror */
          /* Safari/Chrome, other WebKit */
          /* Firefox, other Gecko */
          box-sizing: border-box;
          /* Opera/IE 8+ */
          box-sizing: border-box;
          /* css3 rec */ }
        .yate .yate_buttons div.yate_sharePopup button {
          float: right;
          margin-left: 5px; }
        .yate .yate_buttons div.yate_sharePopup .svgImg {
          display: block;
          height: inherit; }
        .yate .yate_buttons div.yate_sharePopup textarea {
          width: 100%; }
  .yate .fullscreenToggleBtns {
    display: inline-block;
    margin-top: 3px; }
    .yate .fullscreenToggleBtns div {
      cursor: pointer;
      width: 20px;
      height: 20px; }
    .yate .fullscreenToggleBtns .yate_smallscreenBtn {
      display: none; }
  .yate .downloadBtns {
    display: inline-block;
    margin-top: 3px; }
    .yate .downloadBtns div {
      cursor: pointer;
      width: 20px;
      height: 20px; }
  .yate .CodeMirror {
    line-height: 1.5em;
    border: 1px solid #d1d1d1; }
  .yate pre {
    font-size: 13px; }
  .yate span.cm-error {
    border-bottom: 2px dotted red; }
  .yate .gutterErrorBar {
    width: 4px; }
  .yate .CodeMirror-fullscreen .fullscreenToggleBtns .yate_smallscreenBtn {
    display: inline-block; }
  .yate .CodeMirror-fullscreen .fullscreenToggleBtns .yate_fullscreenBtn {
    display: none; }
  .yate .CodeMirror-foldmarker {
    color: #6E2500;
    text-shadow: #FF935E 1px 1px 2px,#FF935E -1px -1px 2px,#FF935E 1px -1px 2px,#FF935E -1px 1px 2px;
    font-size: 19px; }
  .yate .matchingVar {
    background-color: #DBDEED;
    border-radius: 6px;
    transition: background 0.5s linear; }
  .yate .backdrop {
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 5;
    display: none; }
  .yate .svgImg {
    display: inline-block; }
  .yate .CodeMirror-fullscreen {
    z-index: 1100; }
  .yate span.shortlinkErr {
    font-size: small;
    color: red;
    font-weight: bold;
    float: left; }
  .yate .completionNotification {
    color: #999;
    background-color: #f7f7f7;
    position: absolute;
    padding: 0px 5px;
    right: 0px;
    bottom: 0px;
    font-size: 90%; }
  .yate .parseErrorIcon {
    width: 15px;
    height: 15px; }
  .yate .yate_tooltip {
    display: inline;
    position: absolute;
    background: #333;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    bottom: 26px;
    color: #fff;
    left: 20%;
    padding: 5px 15px;
    position: absolute;
    width: 220px;
    white-space: -moz-pre-wrap !important;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    white-space: pre-wrap;
    /* css-3 */
    white-space: normal;
    z-index: 1200; }
  .yate .notificationLoader {
    width: 18px;
    height: 18px;
    vertical-align: middle; }

.CodeMirror-hints {
  position: absolute;
  z-index: 1200;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 2px;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 1px solid silver;
  background: white;
  font-size: 90%;
  font-family: monospace;
  max-height: 20em;
  overflow-y: auto;
  z-index: 1200; }

.CodeMirror-hint {
  margin: 0;
  padding: 0 4px;
  border-radius: 2px;
  white-space: pre;
  color: black;
  cursor: pointer; }

li.CodeMirror-hint-active {
  background: #08f;
  color: white; }

.CodeMirror-hint {
  max-width: 30em; }
